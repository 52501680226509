/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */
.sidebar {
  min-width: 240px;
  max-width: 240px;
  background-attachment: fixed;
  background-repeat: repeat-x;
  background-color: #f1f3f6;
  position: fixed;
  color: #4a4a4a;
  margin-right: -240px;
  transition: all 0.5s;
}

.home-logo {
  object-fit: contain;
  height: auto;
  width: 70px;
  text-align: center;
}

.sidebar.is-open {
  margin-right: 1px;
  transition: 0.5s;
}

.sidebar-footer {
  /* height: 15vh; */
  cursor: pointer;
  position: absolute;
  bottom: 30px;
  padding-left: 10px;
  margin: 10px;
}

.sidebar-header {
  /* width: 100%;
  /* height: 23vh; */
  /* padding-top: 15px; */
}

.sidebar-header h3 {
  color: #4a4a4a;
  padding: 1em;
}

.sidebar ul p {
  color: #4a4a4a;
  padding: 10px;
}

.sidebar a {
  color: #4a4a4a;
  float: right;
}

.sidebar a:hover {
  background-color: transparent;
  font-weight: 500;
}

.sidebar a:focus {
  /* color: #01b779; */
  background-color: transparent;
  font-weight: 500;
}

.side-item-active {
  font-weight: 500;
  color: #01b779 !important;
}

.sidebar hr {
  background: #d4d4d4;
  width: 100%;
}

.sidebar .nav-item:hover {
  background-color: transparent;
}

.menu-open {
  color: #01b779;
}

.nav-item:hover {
  /* color: #01b779; */
  background-color: transparent;
}

.items-menu {
  color: #4a4a4a;
}

li a.dropdown-toggle::after {
  display: inline-flex;
  position: relative;
  left: 60%;
  top: 10%;
}

.sidebar-header > span {
  position: relative;
  float: right;
  margin: 0.5em;
  font-size: 2rem;
  cursor: pointer;
  display: none;
}

.side-menu {
  /* height: 77vh; */
  height: 80vh;
  overflow-y: scroll;
}

.side-menu::-webkit-scrollbar {
  width: 10px;
}

.side-menu::-webkit-scrollbar-thumb {
  border-radius: 3px;
}

.side-menu::-webkit-scrollbar-thumb:hover {
  background: #4a4a4a;
}

.navitem-logo {
  object-fit: contain;
  height: auto;
  width: 15px;
  margin-right: 5px;
}

.side-item-title {
  margin-right: 5px;
  cursor: pointer;
}

.now-ui-icons.active {
  color: #01b779;
}

@media only screen and (min-height: 900px) {
  .side-menu {
    /* height: 77vh; */
    height: 83vh !important;
    overflow-y: scroll;
  }
}
@media only screen and (max-width: 500px) {
  .sidebar-header {
    height: 40vh !important;
  }
  .side-menu {
    height: 60vh;
  }
  .content.is-open {
    margin-right: 100%;
  }

  .sidebar.is-open {
    min-width: 100%;
    max-width: 100%;
    margin-right: 0;
    transition: all 0.5s, height 0s;
  }

  .sidebar.is-open > .sidebar-header span {
    display: unset;
  }

  li a.dropdown-toggle::after {
    display: inline-block;
    position: relative;
    right: 68%;
  }

  .app-content.is-sidebar-open {
    display: none;
  }
  .app-view.is-sidebar-open {
    display: none;
  }
}
