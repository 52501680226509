ol.breadcrumb {
  padding: 0rem 1rem !important;
  align-self: center !important;
  background-color: transparent !important;
}

li.breadcrumb-item a {
  color: #01b779;
}

.breadcrumbs-container {
  display: flex;
}
