.signup {
  .app-content {
    margin-bottom: 0px !important;
    min-height: 90vh !important;
    background: rgb(215, 215, 215);
    background: linear-gradient(
      180deg,
      rgba(215, 215, 215, 1) 0%,
      rgba(238, 238, 238, 1) 100%
    );
  }

  .view-content {
    background: rgb(215, 215, 215) !important;
    background: linear-gradient(
      180deg,
      rgba(215, 215, 215, 1) 0%,
      rgba(238, 238, 238, 1) 100%
    ) !important;
    width: 100vw;
    align-self: center;
  }

  .bg-image {
    background-image: url(../../../../assets/img/signup_bg.png);
    background-size: cover;
    background-repeat: no-repeat;
  }

  .login-alert {
    margin-left: 2px;
    font-size: 0.8em;
    color: #fff;
    font-weight: bold;
  }

  .info-top-icon {
    width: 40px;
    height: auto;
    object-fit: contain;
  }

  .login-content {
    border-radius: 0px !important;
    border: 0px solid !important;
  }

  .content-footer {
    height: 25vh;
    width: 90%;
    margin-left: 5% !important;
    margin-right: 5% !important;
    margin-bottom: 2% !important;
    border-width: 1px;
    background-color: #fff !important;
    border-color: #bbbbbb;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .on-secondary {
    color: white;
    font-weight: 600;
  }

  h2,
  h3,
  h4 {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  h6 {
    font-weight: 400;
    text-transform: none;
  }

  .bottom-cgu {
    max-width: 380px;
  }

  .password-input {
    position: relative;
    cursor: pointer;
  }

  .input-addon {
    position: absolute;
    width: 20px;
    height: auto;
    object-fit: contain;
    right: 10px;
    bottom: 15px;
  }

  .auth-title {
    font-size: 1.8em !important;
  }

  .login-title-view {
    padding-top: 27px;
    padding-left: 3%;
  }

  .success-content {
    width: 95vw;
    min-height: 80vh;
    background: #ffffff;
    border-radius: 10px;
    border: 1px solid #bbbbbb;
    align-self: center;
    margin-top: 15vh;
    margin-bottom: 20px;
  }

  @media screen and (max-width: 906px) {
    .bg-image {
      background-size: cover;
      min-height: 80vh;
    }
  }
}
