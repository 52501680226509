.input-group > .form-control:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 6px !important;
  border-top-left-radius: 6px !important; 
}
.input-group>.input-group-append>.input-group-text {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 0px !important;
    border-top-left-radius: 0px !important; 
}
.input-group .form-control:first-child {
    border-right: 1px solid  none !important;
}
.input-group .btn-secondary.dropdown-toggle{
    background-color: #ffffff !important;
    border: 1px solid #e3e3e3 !important;
    color: black !important;
    height: 50px;
    font-weight: bold;
    z-index: 0 !important;
}
.input-group .btn-secondary.dropdown-toggle:hover {
    box-shadow: none !important;
}
.input-group .btn {
    margin: 0 !important;
    padding: 12px 14px !important;
}
.input-group .custom-input {
    width: 95px !important;
    border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 5px !important;
  border-top-left-radius: 5px !important; 

}
.input-group .dropdown-menu {
    min-width: 3rem !important;
    padding-bottom: 0rem !important;
    padding-top: 0rem !important;
    border-bottom-right-radius: 5px !important;
  border-bottom-left-radius: 6px !important;
}

.input-group .dropdown-menu:before {
    display: none !important;
}

.input-group .dropdown-menu .dropdown-item {
    text-align: center;
    font-weight: bold;
    margin-top: 0px;
    padding: 12px 14px !important;
}
.input-group .dropdown-divider{
    margin: 0px !important;
}