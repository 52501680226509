.btn-upload {
  position: relative;
}

.btn-upload-container {
  width: 150px;
  height: 150px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(248, 248, 248, 0.8);
  cursor: pointer;
  margin: 20px 0px 20px 20px;
}

.btn-upload-container.error {
  background-color: rgba(199, 36, 36, 0.2) !important;
  border: 1px solid rgba(199, 36, 36, 1) !important;
}

.pack-photo-remove {
  width: 17px;
  height: auto;
  object-fit: contain;
  position: absolute;
  top: 4px;
  right: 130px;
  cursor: pointer;
}
