.space-left {
  margin-left: 20px;
}

.creation-form {
  margin-top: 20px;
}

.switch-input {
  display: flex;
  height: 60px;
  align-items: center;
}

.list-Utilisateurs .css-1j70apf-control {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-width: 0px;
  cursor: default;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  min-height: 38px;
  outline: 0 !important;
  position: relative;
  -webkit-transition: all 100ms;
  transition: all 100ms;
  box-sizing: border-box;
  height: 34px;
  width: 100%;
  color: #373737;
  font-size: 12px;
  font-weight: 400;
  width: 200px;
  background: white;
  border: 1px solid #f1f1f1f1;
  border-radius: 35px;
}
.list-Utilisateurs .css-151t60b-control {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-width: 0px;
  cursor: default;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  min-height: 38px;
  outline: 0 !important;
  position: relative;
  -webkit-transition: all 100ms;
  transition: all 100ms;
  box-sizing: border-box;
  height: 34px;
  width: 100%;
  color: #373737;
  font-size: 12px;
  font-weight: 400;
  width: 200px;
  background: white;
  border: 1px solid #f1f1f1f1;
  border-radius: 35px;
}

.list-Utilisateurs .css-1f6hjcp-container {
  background-color: white;
}

.list-Utilisateurs .css-151t60b-control:hover {
  border-color: none !important;
}
.list-Utilisateurs .css-151t60b-control:focus {
  border-color: none !important;
}

.list-Utilisateurs .filter-input {
  width: 200px !important;
  height: 35px;
  margin-bottom: 11px !important;
}

.list-Utilisateurs .css-167ug0s-control {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-width: 0px;
  cursor: default;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  min-height: 38px;
  outline: 0 !important;
  position: relative;
  -webkit-transition: all 100ms;
  transition: all 100ms;
  box-sizing: border-box;
  color: #373737;
  font-size: 12px;
  font-weight: 400;
  width: 192px;
  height: 39px;
  background: #f8f8f8;
  border: 1px solid #f1f1f1f1;
  border-radius: 35px;
}
.list-Utilisateurs .css-167ug0s-control:hover {
  border-color: none !important;
}
.list-Utilisateurs .css-1izjvq5-control {
  -webkit-align-items: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  border-width: 0px !important;
  cursor: default !important;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex !important;
  -webkit-flex-wrap: wrap !important;
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
  -webkit-box-pack: justify !important;
  box-shadow: none !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  min-height: 38px !important;
  outline: 0 !important;
  position: relative !important;
  -webkit-transition: all 100ms !important;
  transition: all 100ms !important;
  box-sizing: border-box !important;
  width: 192px !important;
  height: 39px !important;
  color: #373737 !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  background: #f8f8f8 !important;
  border: 1px solid #f1f1f1f1 !important;
  border-radius: 35px !important;
}

.sousTitle {
  font-size: 21px;
  color: #707070;
  letter-spacing: 0;
  margin-left: 16px;
}
.deleteRow {
  font-size: 18px;
  color: #4a4a4a;
  letter-spacing: 0;
  cursor: pointer;
  margin-top: 20px;
}
.deleteRow:hover {
  font-weight: bold;
  font-size: 18px;
  color: #4a4a4a;
  letter-spacing: 0;
  cursor: pointer;
}

.creation-plusieur-form {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
}

.creation-plusieur-form .title {
  font-size: 24px;
  color: #4a4a4a;
  letter-spacing: 0;
  text-align: center;
}

.css-1chxlti-container {
  order: 1px solid #e3e3e3;
  height: 52px;
}
.list-Utilisateurs .css-151t60b-control:hover {
  border-color: hsl(0, 0%, 70%);
}
.list-Utilisateurs .css-1chxlti-container {
  background-color: transparent !important;
  margin-left: 7px;
}

.list-Utilisateurs .css-1f6hjcp-container {
  background-color: white;
}

.list-Utilisateurs .css-151t60b-control:hover {
  border-color: none !important;
}
.list-Utilisateurs .css-151t60b-control:focus {
  border-color: none !important;
}

.MuiAlert-action {
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: 0px !important;
  padding-left: 0px !important;
}
.MuiAlert-filledSuccess {
  background-color: #008d67 !important;
}
.MuiAlert-message {
  padding: 8px 5px !important;
}

@media only screen and (max-width: 840px) {
  .filters-view {
    justify-content: flex-start !important;
  }
}

.lableInput {
  float: right;
  color: #373737;
  text-align: right;
}

.InputUpload {
  .input-addon {
    position: absolute;
    width: 20px;
    height: auto;
    object-fit: contain;
    left: 30px;
    bottom: 23px;
  }
  .lableInput {
    float: right;
    color: #373737;
    margin-bottom: 10px;
    text-align: right;
  }
}
