/* Chrome, Safari, Edge, Opera */

.card {
  background-color: transparent !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.app-logo {
  width: 100%;
  text-align: center;
}
.app-logo img {
  width: 200px;
  height: auto;
  cursor: pointer;
}

.app-container {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  min-height: 70vh;
}

.app-content {
  width: 100%;
  min-height: 100vh;
  background: #ffffff;
  align-self: center;
}
.app-layout {
  display: flex;
  width: 100%;
  align-items: stretch;
}

.app-view {
  width: 100%;
  padding-left: 2%;
  padding-right: 2%;
  overflow-x: auto;
  padding-top: 10vh;
}

.app-view.is-sidebar-open {
  width: calc(100vw - 195px);
  margin-right: 250px;
  transition: all 0.5s;
}

.page-description {
  padding-left: 15px;
}

.page-description h3 {
  font-weight: bold;
  margin-bottom: 0px !important;
}

.top-btn-view {
  display: flex;
  justify-content: space-between;
}

.app-main {
  min-height: 80vh;
  background-color: #fff;
}

.app-footer-view {
  margin-bottom: 20px;
  padding-left: 3%;
}

.text-black {
  color: #444b54;
}

.text-bold {
  font-family: Avenir;
  font-weight: 700;
}

.text-center {
  display: flex;
  justify-content: center;
}

.auth-error {
  margin-top: 10px;
  font-weight: normal;
  text-transform: none;
  text-align: center;
  color: #bc0206;
}

.menu-icon {
  width: 15px;
  height: 15px;
  margin-right: 10px;
}

.home-navlink {
  color: #373737 !important;
  text-transform: none;
  font-size: 15px;
}

.app-name {
  color: #373737;
  font-size: 15px;
}

.back-icon {
  width: 15px;
  height: auto;
}

.btn-shadow {
  background-color: #ffffff !important;
  -webkit-box-shadow: 0px 2px 24px -8px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 2px 24px -8px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 2px 24px -8px rgba(0, 0, 0, 0.75);
}

.bg-white {
  background-color: #ffffff !important;
}
.cycleCourt .custom-input {
  background-color: white !important;
  border: 1px solid #f1f1f1 !important;
  border-radius: 35px !important;
  height: 38px !important;
  width: 100% !important;
  color: #373737 !important;
  font-size: 14px !important;
  font-weight: bold !important;
  padding: 0px 33px;
  display: flex;
  align-items: center;
}
.creation-date-input,
.custom-input {
  background-color: #f8f8f8 !important;
  border: 0px solid #f8f8f8;
  border-radius: 5px !important;
  height: 50px !important;
  width: 100% !important;
  color: #373737 !important;
  font-size: 1.05em !important;
  font-weight: bold !important;
  display: flex;
  align-items: center;
  padding: 20px;
}

.creation-date-input.error,
.custom-input.error {
  background-color: rgba(199, 36, 36, 0.2) !important;
  border: 1px solid rgba(199, 36, 36, 1) !important;
}

.app-input-desc {
  color: #444b54 !important;
  font-size: 0.7em !important;
  font-weight: 200;
  line-height: 0.5 !important;
}

.custom-area {
  height: 100px !important;
}

.input-addon {
  position: absolute;
  width: 20px;
  height: auto;
  object-fit: contain;
  left: 10px;
  bottom: 15px;
}

/* .creation-date-input {
  text-align: end;
  margin-right: 10px;
} */

.company-info {
  background: rgba(215, 215, 215, 1);
  background: -moz-linear-gradient(
    top,
    rgba(215, 215, 215, 1) 0%,
    rgba(209, 209, 209, 1) 49%,
    rgba(219, 219, 219, 1) 64%,
    rgba(226, 226, 226, 1) 100%
  );
  background: -webkit-gradient(
    left top,
    left bottom,
    color-stop(0%, rgba(215, 215, 215, 1)),
    color-stop(49%, rgba(209, 209, 209, 1)),
    color-stop(64%, rgba(219, 219, 219, 1)),
    color-stop(100%, rgba(226, 226, 226, 1))
  );
  background: -webkit-linear-gradient(
    top,
    rgba(215, 215, 215, 1) 0%,
    rgba(209, 209, 209, 1) 49%,
    rgba(219, 219, 219, 1) 64%,
    rgba(226, 226, 226, 1) 100%
  );
  background: -o-linear-gradient(
    top,
    rgba(215, 215, 215, 1) 0%,
    rgba(209, 209, 209, 1) 49%,
    rgba(219, 219, 219, 1) 64%,
    rgba(226, 226, 226, 1) 100%
  );
  background: -ms-linear-gradient(
    top,
    rgba(215, 215, 215, 1) 0%,
    rgba(209, 209, 209, 1) 49%,
    rgba(219, 219, 219, 1) 64%,
    rgba(226, 226, 226, 1) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(215, 215, 215, 1) 0%,
    rgba(209, 209, 209, 1) 49%,
    rgba(219, 219, 219, 1) 64%,
    rgba(226, 226, 226, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d7d7d7', endColorstr='#e2e2e2', GradientType=0 );
}

.auth-btn {
  width: 70% !important;
}

.creation-page {
  width: 100vw;
  height: 100vh;
  background: #f8f8f8;
}

.title-view {
  width: 100%;
  min-height: 50px;
  display: flex;
  align-items: center;
  padding-top: 5px;
  padding-bottom: 5px;
}

.creation-title {
  margin-left: 20px;
  font-size: 1.7em;
  font-weight: bold;
  font-family: "Monserrat";
}

.creation-form {
  width: 100%;
  height: 100%;
  padding-left: 20px;
  padding-right: 10px;
  margin-top: 10px;
  margin-bottom: 5px;
}

.div-fixed {
  height: 80vh;
  width: 100%;
  overflow-y: scroll;
}

.rdtCount {
  border: 0px !important;
}

.btn.btn-input {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  padding-top: 10px !important;
}

.btn-input {
  width: 100%;
  min-height: 50px !important;
  margin: 0 !important;
  background-color: rgba(159, 157, 157, 0.29) !important;
  color: #373737 !important;
  color: #373737 !important;
  font-size: 1.05em !important;
  font-weight: bold !important;
}

.custom-tag {
  height: 50px;
  width: 140px;
  border-radius: 30px;
  border: 2px solid #60666e;
  margin: 5px 5px;
  padding: 10px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.tag-active {
  background: #60666e;
}

.tag-label {
  color: #60666e;
  font-size: 1em;
  text-align: center;
}

.label-active {
  color: #ffffff;
}

.creation-footer {
  margin: 10px 0px;
  display: flex;
  justify-content: flex-start;
}

.file-input-hidden {
  display: none;
}

.dropdown-arrow {
  width: 20px;
  height: auto;
}

.dropdown-label.bold {
  font-weight: bold;
}

.dropdown-options {
  max-height: 200px !important;
  overflow-y: scroll;
}

.button-shaddow {
  -webkit-box-shadow: 14px 4px 21px 5px rgba(0, 0, 0, 0.13);
  -moz-box-shadow: 14px 4px 21px 5px rgba(0, 0, 0, 0.13);
  box-shadow: 14px 4px 21px 5px rgba(0, 0, 0, 0.13);
  border-radius: 50%;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center !important;
  padding-left: 10px !important;
}
.modal-title {
  color: #444b54;
  font-weight: bold;
  font-size: 1.1em !important;
  text-align: center;
}

.modal-close-btn {
  cursor: pointer;
}

.notice-header {
  justify-content: center !important;
}

.notice-footer {
  justify-content: flex-end !important;
}

.modal-footer.logout {
  justify-content: center !important;
}

.btn-round.logout {
  margin-left: 10px !important;
  margin-right: 10px !important;
  min-width: 80px !important;
}

.dropdown-item .color-primary {
  background-color: #0074af !important;
  width: 20px !important;
  height: 20px !important;
  border-radius: 10px;
}

.dropdown-item .color-secondary {
  background-color: #3cc47c !important;
  width: 20px !important;
  height: 20px !important;
  border-radius: 10px;
}

.dropdown-item .color-red {
  background-color: #d80d0d !important;
  width: 20px !important;
  height: 20px !important;
  border-radius: 10px;
}

.login100-form-title {
  font-family: Poppins-Bold;
  font-size: 24px;
  color: #ffffff;
  line-height: 1.2;
  text-align: center;
  width: 100%;
  display: block;
  padding-bottom: 54px;
}

/*---------------------------------------------*/
.wrap-input100 {
  position: relative;
  width: 100%;
  z-index: 1;
  margin-bottom: 10px;
}

.input100 {
  font-size: 20px;
  line-height: 1.5;
  color: #ffffff;
  display: block;
  width: 350px;
  background: #8c878e;
  height: 62px;
  border-radius: 35px;
  padding: 0 30px 0 68px;
  border: 3px solid #ffffff;
  margin-bottom: 35px;
}

.symbol-input100 {
  font-size: 15px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  position: absolute;
  border-radius: 25px;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-left: 35px;
  pointer-events: none;
  color: #ffffff;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.MuiAvatar-root {
  width: 100% !important;
  height: 100% !important;
}

.table td,
.table th {
  text-align: center !important;
}
.button-shaddow {
  box-shadow: none !important ;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  display: flex;
  padding: 0px !important;
}

.dropdown-menu:before {
  left: 176px !important;
}
.dropdown-menu {
  height: 173px !important;
  overflow: scroll !important;
}

@media screen and (max-width: 991px) {
  .navbar .dropdown.show .dropdown-menu,
  .navbar .dropdown .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 10rem;
    padding: 0.5rem 0;
    /* margin: 0.125rem 0 0 !important; */
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff !important;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15) !important;
    border-radius: 0.25rem;
  }
  .dropdown-item {
    padding: 13px 25px !important;
  }
}
.themeColor {
  background-color: #007f5d !important;
}
