.top-bar-icon {
  width: 40px;
  height: auto;
  object-fit: contain;
}

.fixed-top {
  background-color: #ffffff !important;
  box-shadow: none;
  right: 0;
  left: 5px;
}

.navbar {
  width: 100%;
}

.navbar-translate {
  width: 100%;
}

.navbar.is-sidebar-open {
  width: calc(97vw - 225px);
  transition: all 0.5s;
}

.navbar.fixed-top.is-sidebar-open {
  right: 251px;
}

.display-row {
  display: flex;
  justify-content: space-between;
}

.topbar-right {
  display: flex;
  width: 90px;
  align-items: center;
  justify-content: flex-end;
}

.user-avatar {
  object-fit: contain;
  width: 40px;
  height: 40px;
  border-radius: 20px;
}

.landing-navbar {
  width: 100% !important;
  min-height: 80px;
  display: flex;
  padding: 40px 25px;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff !important;
  position: fixed;
  z-index: 10;
}

.nav-right-item {
  height: 40px;
  min-width: 120px;
  padding: 5px 5px;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-family: Montserrat;
  font-weight: 700;
}

.nav-right-item:hover {
  background-color: #f8f8f8;
}

@media only screen and (max-width: 500px) {
  .fixed-top {
    right: 0;
    left: 0;
  }

  .navbar-translate {
    width: 100% !important;
  }
}
